import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div>
        Hello world, i am trying to see whther i can make these works with hosting environment
      </div>
    </div>
  );
}

export default App;
